import form from './form.json';
import newsletterForm from './newsletterForm.json';
import recaptchaLegal from './recaptchaLegal.json';
import pricing from './pricing.json';
import search from './search.json';

export default {
  translation: {
    form,
    newsletterForm,
    recaptchaLegal,
    pricing,
    search,
  },
};
