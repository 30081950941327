const sortModalsPerLanguages = (
  allKontentItemModalV2: Queries.GeneralContextQuery['allKontentItemModalV2']
): ModalsV2PerLanguage => {
  const modals: ModalsV2PerLanguage = allKontentItemModalV2.edges.reduce(
    (all, { node: current }) => {
      if (!(current.preferred_language in all)) {
        all[current.preferred_language] = {};
      }

      all[current.preferred_language][current.system.id] = current;
      return all;
    },
    {} as ModalsV2PerLanguage
  );

  return modals;
};

export default sortModalsPerLanguages;
