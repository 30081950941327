import { createContext, type ReactNode, useMemo } from 'react';

type GeneralContextType = {
  internalLinks: InternalLinks;
  modalDefinitions: ModalsV2PerLanguage;
};

const defaultContextValue: GeneralContextType = {
  internalLinks: {},
  modalDefinitions: {},
};

export const GeneralContext = createContext<GeneralContextType>(defaultContextValue);

interface Props {
  children: ReactNode;
  internalLinks?: InternalLinks;
  modalDefinitions?: ModalsV2PerLanguage;
}

export const GeneralContextProvider = ({
  children,
  internalLinks = {},
  modalDefinitions = {},
}: Props) => {
  const value = useMemo(
    () => ({
      internalLinks,
      modalDefinitions,
    }),
    [internalLinks, modalDefinitions]
  );

  return <GeneralContext.Provider value={value}>{children}</GeneralContext.Provider>;
};

export default GeneralContext;
