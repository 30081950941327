import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import '../../locales';

import { GeneralContextProvider } from '@/context/GeneralContext';
import GlobalStyles from '../styles/GlobalStyles';
import StructuredData from '@/components/StructuredData/StructuredData';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import getInternalLinks from '@/utils/getInternalLinks';
import sortModalsPerLanguages from '@/utils/sortModalsPerLanguage';

interface Props {
  children: JSX.Element;
}

const currentProjectID = process.env.GATSBY_KONTENT_PROJECT_ID;

const structuredData = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Sonar',
  legalName: 'SonarSource SA',
  url: 'https://www.sonarsource.com',
  logo:
    `https://assets-eu-01.kc-usercontent.com/` +
    `${currentProjectID}/` +
    `5590df23-cc3a-4487-a3dd-e5dcb2da8731/sonar-logo-horizontal.svg`,
  foundingDate: '2008',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'PO Box 765',
    addressLocality: 'Geneva',
    addressRegion: '15',
    postalCode: 'CH-1215',
    addressCountry: 'Switzerland',
  },
  contactPoint: {
    '@type': 'ContactPoint',
    contactType: 'customer support',
    email: 'https://www.sonarsource.com/company/contact/',
  },
  sameAs: [
    'https://sonarlint.org',
    'https://sonarqube.org',
    'https://sonarcloud.io',
    'https://www.youtube.com/c/SonarSource',
    'https://www.facebook.com/SonarSource/',
    'https://www.reddit.com/user/SonarSource/',
    'https://www.linkedin.com/company/sonarsource/',
    'https://twitter.com/sonarsource',
  ],
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const Layout: React.FC<Props> = ({ children }) => {
  const pageTreeQueryResult = useStaticQuery<Queries.GeneralContextQuery>(graphql`
    query GeneralContext {
      allKenticoLink {
        nodes {
          codename
          url
          language
        }
      }

      allKontentItemModalV2 {
        edges {
          node {
            ...MODAL_V2
          }
        }
      }
    }
  `);

  const internalLinks = useMemo(
    () => getInternalLinks(pageTreeQueryResult.allKenticoLink),
    [pageTreeQueryResult.allKenticoLink]
  );
  const modalDefinitions = useMemo(
    () => sortModalsPerLanguages(pageTreeQueryResult.allKontentItemModalV2),
    [pageTreeQueryResult.allKontentItemModalV2]
  );

  return (
    <QueryClientProvider client={queryClient}>
      <noscript>
        <iframe
          title="CHEQ"
          src="https://obseu.testrobotflower.com/ns/8851a52c7a2742ba5feb9d36b5ff65b8.html?ch=cheq4ppc"
          width="0"
          height="0"
          style={{ display: 'none' }}
        />
      </noscript>
      <GlobalStyles />
      <StructuredData schema={structuredData} />
      <GeneralContextProvider internalLinks={internalLinks} modalDefinitions={modalDefinitions}>
        {children}
      </GeneralContextProvider>
    </QueryClientProvider>
  );
};

export default Layout;
