import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getLanguagesList } from '@/utils/localization';
import en from './en';
import jp from './jp';
import fr from './fr';
import de from './de';
import es from './es';
import zh from './zh';
import ko from './ko';

const resources = {
  en,
  jp,
  fr,
  de,
  es,
  zh,
  ko,
};

const options = {
  resources,
  supportedLngs: getLanguagesList(),
  fallbackLng: 'en',
  detection: {
    order: ['path', 'htmlTag'],
    lookupFromPathIndex: 0,
  },
  interpolation: {
    escapeValue: false,
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init(options);

export default i18n;
